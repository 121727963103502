import { useState } from 'react';
import './App.css';
import FactorList from './components/FactorList';
import Header from './components/Header';
import ServiceList from './components/ServiceList';

function App() {
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [selectedServices, setSelectedServices] = useState([]);

  return (
    <div>
      {/* 
      <div class="print-page justify-content-center">

        <div class="table-container">
          
        <div className='title-factor'>فاکتور پرداخت کفاتیر (ممد)</div>
          <div class="row">
            <div class="title fw-700">عنوان</div>
            <div class="price fw-700">قیمت</div>
          </div>

          <div class="row">
            <div class="title">آکوستیک</div>
            <div class="price">457,444</div>
          </div>

          <div class="row total-row">
            <div class="title">مجموع:</div>
            <div class="price">547,525 تومان</div>
          </div>
        </div>

      </div> */}

      <Header setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
      <div className='d-flex justify-content-center'>
        <ServiceList setSelectedServices={setSelectedServices} selectedServices={selectedServices} selectedCategory={selectedCategory} />
        <FactorList setSelectedServices={setSelectedServices} selectedServices={selectedServices} />
      </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react';

const ServiceList = ({ selectedCategory, selectedServices, setSelectedServices }) => {

    const [services] = useState([
        { "service_id": 1, "category_id": 1, "title": "اصلاح موی سر", "price": 480000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 2, "category_id": 1, "title": "رنگ مو", "price": 550000, "picture": require('../assets/images/service-list-4.png') },
        { "service_id": 3, "category_id": 2, "title": "کوتاهی مو", "price": 350000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 4, "category_id": 2, "title": "تراکم مو", "price": 600000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 5, "category_id": 3, "title": "پاکسازی صورت", "price": 250000, "picture": require('../assets/images/service-list-4.png') },
        { "service_id": 6, "category_id": 3, "title": "کوتاهی و اصلاح ابرو", "price": 300000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 7, "category_id": 2, "title": "اصلاح سر و ریش", "price": 400000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 8, "category_id": 4, "title": "ماساژ صورت", "price": 200000, "picture": require('../assets/images/service-list-4.png') },
        { "service_id": 9, "category_id": 3, "title": "اپیلاسیون", "price": 700000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 10, "category_id": 1, "title": "کوتاهی ناخن", "price": 150000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 11, "category_id": 6, "title": "ماساژ گوگولی", "price": 130000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 12, "category_id": 5, "title": "ماساژ گوش", "price": 10000, "picture": require('../assets/images/service-list-2.png') }
    ]);


    const addToSelectedServices = (service) => {
        const isServiceSelected = selectedServices.some(selectedService => selectedService.service_id === service.service_id);
        if (!isServiceSelected) {
            setSelectedServices(prevSelectedServices => [...prevSelectedServices, service]);
        } else {
            alert('این خدمت قبلاً انتخاب شده است!');
        }
    };

    const filteredServices = services.filter(service => service.category_id === selectedCategory);



    return (
        <div className='service-list boxes'>
            <div className='container flex-wrap'>
                {filteredServices.map((service, index) => (
                    <div className='box' key={index}>
                        <img src={service.picture} alt='img service list' />
                        <div className='d-flex justify-content-space-between align-items-center mt-20 mb-16'>
                            <div className='fs-20 fw-700'>{service.title}</div>
                            <div className='d-flex align-items-center price fs-24 fw-700'>{service.price.toLocaleString()}<div className='text-gray fs-12 mr-3'>تومان</div></div>
                        </div>
                        <button className='service-list-button bg-button-purple pointer' onClick={() => addToSelectedServices(service)}>افزودن به لیست خدمات</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceList;